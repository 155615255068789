<template>
    <div class="includepage">
            <section class="blockElement space bg-white whoWeAre">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="boxed bodyBG text-center">
                                <div class="imgSrc">
                                    <img src="/assets/images/aboutBlub.svg" :alt="$t('about.text422')" :title="$t('about.text423')" />
                                </div>
                                <div class="content">
                                    <h1 class="mb-md-4 f-32 semibold tag">{{$t('about.text1')}} <span class="secondary">{{$t('about.text2')}} </span></h1>
                                    <p>{{$t('about.text3')}}</p>
                                    <p>{{$t('about.text4')}}</p>
                                    <h5 class="secondary">{{$t('about.text5')}}</h5>
                                    <p>{{$t('about.text6')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space bg-white">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-8 mb-4 text-center">
                            <h5 class="mb-2 secondary">{{$t('about.text7')}}</h5>
                            <h2>{{$t('about.text8')}} <span class="secondary">{{$t('about.text9')}}</span><br> {{$t('about.text10')}}</h2>
                            <!-- <p class="bold">We are ZuluTrade, a <span class="secondary">FINVASIA Group</span> company and one of the most innovative Copy & Social Trading platforms in the world - proud to be one of the most transparent.</p> -->
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space bg-white automated5 pt-0" v-if="!COMMON.isCobrandedDomain()">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <h2 class="text-center mb-md-4 pb-4">{{$t('about.text11')}}</h2>
                        </div>
                        <div class="col-12">
                            <ul class="clientLogo d-flex align-items-center flex-wrap justify-content-center" v-if="store.allBrokersList.length">
                                <li v-for="list,key in store.allBrokersList" :key="key">
                                    <a :href="list.website" target="_blank">
                                        <v-lazy-image  :src="`/assets/images/clientLogo/${list.logoUrl.split('/')[3].split('.')[0]}.svg`" :alt="list.name" :title="list.name" width="120"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 text-center">
                            <router-link to="/select-broker" class="secondary">{{$t('about.text12')}}</router-link>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-12 mb-md-4">
                            <h2>{{$t('about.text13')}} <span class="secondary">{{$t('about.text14')}}</span></h2>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon one">
                                    <span class="f-14 semibold">{{$t('about.text15')}} <span>{{$t('about.text12a')}}</span> </span>
                                    <span class="f-14 semibold">{{$t('about.text12b')}}</span>
                                </div>
                                <h6 class="f-15">{{$t('about.text17')}} <span>{{$t('about.text18')}} {{$t('about.text12a')}}</span></h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon one">
                                    <span class="f-14 semibold">{{$t('about.text15')}} <span>{{$t('about.winner13a')}}</span></span>
                                    <span class="f-14 semibold">{{$t('about.text15b')}}</span>
                                </div>
                                <h6 class="f-15">{{$t('about.text17')}} <span>{{$t('about.text18')}}</span> </h6>
                            </div>
                        </div>
                            <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-14 semibold">{{$t('about.text15')}}</span>
                                    <span class="f-14 semibold">{{$t('about.text448')}}</span>
                                </div>
                                <h6 class="f-15">{{$t('about.text17')}} <span>{{$t('about.text449')}}</span></h6>
                            </div>
                        </div>
                            <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-14 semibold">{{$t('about.text450')}}</span>
                                    <span class="f-14 semibold">{{$t('about.text451')}}</span>

                                </div>
                                <h6 class="f-15">{{$t('about.text452')}} <span>{{$t('about.text22')}}</span></h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-14 semibold">{{$t('about.text15')}}</span>
                                    <span class="f-14 semibold">{{$t('about.text23')}}</span>
                                </div>
                                <h6 class="f-15">{{$t('about.text17')}} <span>{{$t('about.text24')}}</span></h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-14 semibold">{{$t('about.text15')}}</span>
                                    <span class="f-14 semibold">{{$t('about.text16')}}</span>
                                </div>
                                <h6 class="f-15">{{$t('about.text17')}} <span>{{$t('about.text18')}}</span></h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="ourAward">
                                <div class="position-relative maskIcon">
                                    <span class="f-14 semibold">{{$t('about.text19')}}</span>
                                    <span class="f-14 semibold">{{$t('about.text20')}}</span>
                                </div>
                                <h6 class="f-15">{{$t('about.text21')}} <span>{{$t('about.text22')}}</span></h6>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="blockElement space ourLocation bg-white">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-6 text-center mb-4">
                            <h2 class="mb-md-4">{{$t('about.text13')}} <span class="secondary">{{$t('about.text25')}}</span></h2>
                            <p class="mb-0"><span class="bold">{{$t('about.text26')}}</span> {{$t('about.text27')}} <span class="bold">{{$t('about.text28')}}</span>.</p>
                            <p class="mb-0">{{$t('about.text29')}} <span class="bold">{{$t('about.text30')}}</span> {{$t('about.text31')}} <span class="bold">{{$t('about.text32')}}</span>.</p>
                            <p>{{$t('about.text33')}} <span class="bold">{{$t('about.text34')}}</span>, <span class="bold">{{$t('about.text28')}}</span>, <span class="bold">{{$t('about.text32')}}</span>, <span class="bold">{{$t('about.text30')}}</span>, <span class="bold">{{$t('about.text35')}}</span>, <span class="bold">{{$t('about.text36')}}</span>, <span class="bold">{{$t('about.text37')}}</span>, <span class="bold">{{$t('about.text38')}}</span> {{$t('about.text39')}} <span class="bold">{{$t('about.text40')}}</span>.</p>
                        </div>
                        <div class="col-12 col-md-8 text-center mapLocation mb-4">
                            <v-lazy-image class="lightTheme" src="/assets/images/mapLocation.svg" :alt="$t('about.text424')" :title="$t('about.text425')" />
                            <v-lazy-image class="darkTheme d-none" src="/assets/images/mapLocationLight.svg" :alt="$t('about.text424')" :title="$t('about.text425')" />
                        </div>
                        <div class="col-12 col-md-10">
                            <div class="boxed services d-flex align-items-center justify-content-center">
                                <v-lazy-image src="/assets/images/loudLayer.svg" :alt="$t('about.text426')" :title="$t('about.text427')" />
                                <h2 class="bold mb-0 mx-3">{{$t('about.text41')}}</h2>
                                <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn px-md-5 ms-3">{{$t('about.text42')}}</router-link>
                                <a v-else :href="'/register/'" class="button fillBtn zulu_btn px-md-5 ms-3">{{$t('about.text42')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                timelineTab:'About',
                appMode : process.env.VUE_APP_MODE
            };
        },
        methods:{
            getBrokersList(){
                this.store.getAllBrokersList({},true,'withoutFlavor')
            },
        },
        created(){
            if(!this.COMMON.isCobrandedDomain()){ this.getBrokersList() }
        }

    }
</script>